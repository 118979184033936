import {Events} from '../constants';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';

const ARTICLE_ID = 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33';

export function getAppManifest(t, locale: string, token: string) {
  const version = getAppVersion();

  const pageActions = {
    default: [
      'Pages_Actions_Page_Rename',
      {
        title: t('Pages_Panel_ShopPage_Settings_Delete'),
        event: Events.deletePage,
        icon: 'deleteAction',
        type: 'page_remove',
      },
    ],
    [PageMap.PRODUCT]: [],
    [PageMap.CART]: [],
    [PageMap.THANKYOU]: [],
  };

  const pageSettings = {
    default: [
      {
        helpId: ARTICLE_ID,
        type: 'page_info',
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
      {
        title: t('Pages_Panel_SEO'),
        type: 'seo',
      },
    ],
    [PageMap.PRODUCT]: [
      {
        helpId: ARTICLE_ID,
        type: 'page_info',
        title: t('Pages_Panel_pageInfo'),
        url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/productPage_${locale}.html?token=${token}`,
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
    ],
    [PageMap.CART]: [
      {
        helpId: ARTICLE_ID,
        type: 'page_info',
        title: t('Pages_Panel_pageInfo'),
        url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/cart_${locale}.html`,
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
    ],
    [PageMap.THANKYOU]: [
      {
        helpId: ARTICLE_ID,
        type: 'page_info',
        title: t('Pages_Panel_pageInfo'),
        url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/thankYouPage_${locale}.html`,
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
    ],
  };

  const applicationSettings = {
    default: {
      displayName: t('Pages_Panel_storesPages'),
      helpId: ARTICLE_ID,
    },
  };

  const applicationActions = {
    default: {
      defaultValues: [
        {
          title: t('Pages_Panel_manageCTA'),
          event: Events.manageStores,
          icon: 'settingsAction',
        },
      ],
    },
  };

  const pageDescriptors = {
    default: {
      icon: 'storesPageType',
      orderIndex: 2,
    },
    [PageMap.PRODUCT]: {
      icon: 'storesPageType',
      orderIndex: 3,
      tooltip: t('Pages_Store_Product_Info_WhatisText'),
    },
    [PageMap.CART]: {
      icon: 'storesPageType',
      orderIndex: 1,
      tooltip: t('Pages_Store_Cart_Info_WhatisText'),
    },
    [PageMap.THANKYOU]: {
      icon: 'storesPageType',
      orderIndex: 0,
      tooltip: t('Pages_Store_Thankyou_Info_WhatisText'),
    },
  };

  return {
    pages: {
      pageActions,
      pageSettings,
      applicationSettings,
      applicationActions,
      pageDescriptors,
    },
  };
}
