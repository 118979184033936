import {ecomAppDefID, membersAppDefId, Events, EDITOR_SCRIPT_DSN, SPECS} from './constants';
import {Experiments} from '../common/experiments/Experiments';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/src/bi/configure-front-bi-logger';
import {
  withMembersArea,
  maybeInstallMembersArea,
  integrateMembersAreaApps,
} from '@wix/members-area-integration-kit/dist/src';
import {Logger} from '@wix/bi-logger-ec-sf';
import {getAppManifest} from './services/appManifest';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {DependantApps} from './services/DependantApps';
import {sliderWidthMigration} from './services/sliderMigration';
import {adiMissingPagesMigration} from './services/adiMissingPagesMigration';
import {translateFunctionFactory} from '../common/translations/translations';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';
import {delay} from '@wix/wixstores-client-core/dist/src/utils/delay';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {reinstallWishlistMigration} from './services/reinstallWishlistMigration';
import {
  setSentryInstance,
  withErrorReportingWrapping,
  wrapAsyncFunctionsWithPromise,
} from '@wix/wixstores-client-core/dist/es/src/viewer-script/errorReporter';

let appToken;
let options;
let t;
let locale: string = 'en';
let biLogger: Logger;
let sdk: IEditorSdk;
let dependantApps: DependantApps;
let storeId: string;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let experiments: Experiments;

async function getExperiments(instance: string): Promise<Experiments> {
  const experimentsResponse = await fetch('/_api/wix-ecommerce-storefront-web/api', {
    method: 'post',
    body: JSON.stringify({
      query: require('!raw-loader!../common/experiments/getConfig.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getConfig',
    }),
    headers: {
      Authorization: instance,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((data) => data.json())
    .then((data) => {
      return (data.data.experiments || []).reduce((acc, e) => {
        acc[e.name] = e.value;
        return acc;
      }, {});
    });
  return new Experiments(experimentsResponse);
}

function createSentryInstance(monitoring) {
  const configuration = {
    dataCallback: (data) => {
      data.environment = 'Worker';
      return data;
    },
  };
  return monitoring.createSentryMonitorForApp(EDITOR_SCRIPT_DSN, configuration);
}

function getAssetsUrl() {
  const version = getAppVersion();
  return `https://static.parastorage.com/services/wixstores-client-worker/${version}/assets`;
}

function isMembersPage(tpaPageId) {
  return tpaPageId === PageMap.ORDER_HISTORY || tpaPageId === PageMap.WISHLIST;
}

async function addStoresPagesAsPanel() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId(appToken, ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  return Promise.all(
    storesPages.map(async (page) => {
      const pageRef = {id: page.id, type: page.type};
      const pageData = await sdk.pages.data.get(appToken, {
        pageRef,
      });
      if (!pageData.managingAppDefId && !isMembersPage(pageData.tpaPageId)) {
        await sdk.pages.data.update(appToken, {
          pageRef,
          data: {managingAppDefId: ecomAppDefID},
        });
        return pageData;
      }
    })
  );
}

async function setStateForPages() {
  const applicationPages = await sdk.document.pages.getApplicationPages(appToken);
  applicationPages
    .filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID)
    .forEach((pageData) => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CART ||
        pageData.tpaPageId === PageMap.THANKYOU
      ) {
        sdk.document.pages.setState(appToken, {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    });
}

const showProgressBar = (shouldInstallMembers: boolean = true): Promise<Function> => {
  let currStep = 1;
  const baseTranslationKey = shouldInstallMembers
    ? 'settings.productPage.loadingModal.addingMembersArea'
    : 'settings.productPage.loadingModal.addingWishlist';
  const progressBarTitle = t(baseTranslationKey);
  const progressBarSubTitles = [
    t(`${baseTranslationKey}.firstSubtitle`),
    t(`${baseTranslationKey}.secondSubtitle`),
    t(`${baseTranslationKey}.thirdSubtitle`),
  ];
  const timeToWaitBetweenSteps = shouldInstallMembers ? 6000 : 3500;

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(async (resolve) => {
    const panelRef = await sdk.editor.openProgressBar(appToken, {
      title: progressBarTitle,
      totalSteps: 3,
      currentStep: currStep,
      stepTitle: progressBarSubTitles[0],
      image: `${getAssetsUrl()}/images/wishlist.svg`,
    });
    await delay(timeToWaitBetweenSteps);
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: ++currStep,
      stepTitle: progressBarSubTitles[1],
    });
    await delay(timeToWaitBetweenSteps);
    await sdk.editor.updateProgressBar(appToken, {
      panelRef,
      currentStep: ++currStep,
      stepTitle: progressBarSubTitles[2],
    });
    await delay(500);
    resolve(() => {
      sdk.editor.closeProgressBar(appToken, false, {panelRef});
    });
  });
};

export async function navigateToWishlist() {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  biLogger.clickToPreviewWishlistInMembersSfe({});
  const wishlistPageRef = await dependantApps.getWishlistPageRef();
  sdk.document.pages.navigateTo(appToken, {pageRef: wishlistPageRef});
}

function createAppApi(shouldUseNewMembersApi = false): AppApiModel {
  return {
    isMembersInstalled: () => {
      return dependantApps.isMembersInstalled();
    },
    installMembersAreaAndWishlistPage: async () => {
      const progressBarPromise = showProgressBar(true);

      shouldUseNewMembersApi ? await maybeInstallMembersArea() : await dependantApps.installMembers();

      shouldUseNewMembersApi
        ? await dependantApps.tryInstallWishlist(t, getAssetsUrl())
        : await dependantApps.installWishlistPageInMembersArea();

      const close = await progressBarPromise;
      await delay(500);
      close();
      await delay(500);
      sdk.editor
        .showUserActionNotification('', {
          message: t('settings.productPage.floatingNotification.membersAreaAdded'),
          type: 'success',
          link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
        })
        .then((linkClicked) => {
          linkClicked && navigateToWishlist();
        });
    },
    installWishlist: async () => {
      const progressBarPromise = showProgressBar(false);

      shouldUseNewMembersApi
        ? await dependantApps.tryInstallWishlist(t, getAssetsUrl())
        : await dependantApps.installWishlistPageInMembersArea();

      const close = await progressBarPromise;
      await delay(500);
      close();
      await delay(500);
      sdk.editor
        .showUserActionNotification('', {
          message: t('settings.productPage.floatingNotification.wishlistAdded'),
          type: 'success',
          link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
        })
        .then((linkClicked) => {
          linkClicked && navigateToWishlist();
        });
    },
    uninstallWishlist: async () => {
      await dependantApps.uninstallWishlistPageInMembersArea();
    },
    isAppInstalled: async (appDefinitionId: string) => {
      return dependantApps.isAppInstalled(appDefinitionId);
    },
  };
}

async function setStoresPages(shouldUseNewMembersApi) {
  const shouldUseLightboxes = experiments.enabled(SPECS.UseLightboxes);
  await setStateForPages();
  await dependantApps.addCheckout();
  shouldUseLightboxes && (await dependantApps.addLighboxes(options.initialAppData.applicationId));
  if (!shouldUseNewMembersApi) {
    await dependantApps.installApps();
    await dependantApps.setDependantMembersAreaApps();
  }
}

const getEditorReadyFn = (shouldUseNewMembersApi = false) => async (_editorSDK, _appToken, _options) => {
  options = _options;
  appToken = _appToken;
  sdk = _editorSDK;

  const instance: string = await (sdk as any).document.info.getAppInstance('token');
  experiments = await getExperiments(instance);

  setSentryInstance(createSentryInstance(options.monitoring));
  wrapAsyncFunctionsWithPromise(true);

  const encodedInstance = instance.substring(instance.indexOf('.') + 1);
  const parsedInstance = JSON.parse(atob(encodedInstance));
  storeId = parsedInstance.instanceId;
  const isMerchant = true;
  biLogger = createStoreFrontBILogger({uuid: parsedInstance.uid}, parsedInstance.biToken, {
    storeId,
    isMerchant,
    appName: 'wixstores worker',
  });
  dependantApps = new DependantApps(sdk, appToken, biLogger, options, instance);
  locale = await sdk.editor.environment.getLocale();
  t = await translateFunctionFactory(locale);
  shouldUseNewMembersApi &&
    (await integrateMembersAreaApps(dependantApps.getMembersAreaAppsToInstall(t, getAssetsUrl())));

  const storesPages = await addStoresPagesAsPanel();
  if (storesPages.length) {
    await setStoresPages(shouldUseNewMembersApi);
  }
  sdk.editor.setAppAPI(appToken, withErrorReportingWrapping(createAppApi(shouldUseNewMembersApi)));
};

const getAppManifestFn = () => getAppManifest(t, locale, appToken);

const onEvent = async (data) => {
  const {eventType, eventPayload} = data;
  switch (eventType) {
    case Events.manageStores:
      return sdk.editor.openDashboardPanel(appToken, {url: 'store/products', closeOtherPanels: false});
    case Events.deletePage: {
      const {pageRef} = eventPayload;
      return sdk.pages.remove(appToken, {pageRef});
    }
    case Events.addShopPage:
      await sdk.tpa.add.component(appToken, {
        appDefinitionId: ecomAppDefID,
        page: {pageId: 'product_gallery'},
        componentType: 'PAGE',
      });
      return addStoresPagesAsPanel();
  }
};

const getHandleActionFn = (shouldUseNewMembersApi = false) => ({type, payload}) => {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  try {
    switch (type) {
      case 'appInstalled':
        // eslint-disable-next-line
        switch (payload.appDefinitionId) {
          case membersAppDefId: {
            return dependantApps.onMembersInstall(shouldUseNewMembersApi);
          }
          default:
            return Promise.resolve();
        }
      case 'migrate':
        // eslint-disable-next-line @typescript-eslint/tslint/config
        switch (payload.type) {
          case 'sliderWidth':
            return sliderWidthMigration(sdk, appToken);
          case 'adiMissingPages':
            return adiMissingPagesMigration(sdk, appToken, storeId, biLogger);
          case 'reinstallWishlist':
            return reinstallWishlistMigration(sdk, appToken, dependantApps);
          case 'tryInstallWishlistTPA':
            return dependantApps.installWishlistTPA();
          default:
            return Promise.resolve();
        }
      default:
        return Promise.resolve();
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

const getControllerPresets = () => Promise.resolve([]);

const editorScriptCommon = {
  getAppManifest: getAppManifestFn,
  onEvent,
  getControllerPresets,
};

export const editorScript = withErrorReportingWrapping({
  editorReady: getEditorReadyFn(false),
  handleAction: getHandleActionFn(false),
  ...editorScriptCommon,
});

export const editorScriptWithNewMembersApi = withMembersArea({
  editorReady: getEditorReadyFn(true),
  handleAction: getHandleActionFn(true),
  ...editorScriptCommon,
});
